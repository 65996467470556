import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BoatCard } from "../../../components/boatCard";
import { Dropdown } from "../../../components/form/dropdown";
import styled from "styled-components";

import { GridButtonIcon } from "../../../components/icon/gridButtonIcon";
import { ListButtonIcon } from "../../../components/icon/listButtonIcon";
import BrokerageApi from "../../../services/api.service";
import { config } from "../../../services/config.service";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { Loader } from "../../../components/loader";
import { Filter } from '../../../components/filter';

const ListWrapper = styled.div`
  background: #E8E8E8;
  min-height: calc(100vh - 275px);
  padding-bottom: 48px;
`;

const ListHeader = styled.div`
  padding-top: 48px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  line-height: 1.3;
  color: #8B8B8B;
`

const Filters = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ListContent = styled.div`
`;

const ViewSwitch = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 992px) {
    padding-top: 15px;
  }
`;

const DropdownSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
`

const GridButton = styled.div`
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.div``;

export const BuilderPage = () => {
  const [viewMode, setViewMode] = React.useState(0);
  const [data, setData] = React.useState(0);
  const [boats, setBoats] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  let params = useParams();

  const init = async () => {
    const result = await BrokerageApi.getBuilder(params.id);

    if (result.success) {
      setData(result.data);
      setLoading(false);
    } else {
      // an error occured
    }
  };

  const initBoats = async (sorting = "") => {
    const boatsResult = await BrokerageApi.getBuilderBoats(params.id, sorting);

    if (boatsResult.success) {
      console.log(boatsResult.data);
      setBoats(boatsResult.data);
    }
  };

  React.useEffect(() => {
    init();
    initBoats();
  }, []);

  const onViewSwitch = (i) => {
    setViewMode(i);
  };

  const onSort = (v) => {
    initBoats(v);
  };

  if (loading) return <Loader />;

  return (
    <React.Fragment>
      <Helmet>
        <title>{data.name} YACHTS | Iconic Yacht Brokerage</title>
      </Helmet>

      <Filter />

      <ListWrapper>
        <ListHeader>
          <Container>
            <Row>
              <Col md="12">
                <Title>{data.name}</Title>
              </Col>

              <Col md={2}></Col>
              <Col md={8}>
                <Description>
                  {data.description}
                </Description>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Filters>
              <Row>
                <Col md={1}>
                  <ViewSwitch>
                    <GridButton
                      selected={viewMode === 0}
                      onClick={() => onViewSwitch(0)}
                    >
                      <GridButtonIcon selected={viewMode === 0} />
                    </GridButton>
                    <ListButton
                      selected={viewMode === 1}
                      onClick={() => onViewSwitch(1)}
                    >
                      <ListButtonIcon selected={viewMode === 1} />
                    </ListButton>
                  </ViewSwitch>
                </Col>

                <Col md={7}></Col>
                <Col md={4}>
                  <DropdownSwitch>
                    <Dropdown
                      fullWidth
                      dark
                      horizontal
                      label="Sort by"
                      options={[
                        {
                          value: "created_at:desc",
                          label: "Sort by: Recommended",
                        },
                        {
                          value: "lengthM:desc",
                          label: "Sort by: Length - Long to Short",
                        },
                        {
                          value: "lengthM:asc",
                          label: "Sort by: Length - Short to long",
                        },
                        {
                          value: "price:desc",
                          label: "Sort by: Price - High to Low",
                        },
                        { value: "price:asc", label: "Sort by: Price - Low to High" },
                      ]}
                      onChange={onSort}
                    />

                  </DropdownSwitch>
                </Col>
              </Row>
            </Filters>

          </Container>
        </ListHeader>
        <ListContent>
          {viewMode === 0 ? (

            <Container>
              <Row>
                {boats.length == 0 ? (
                  <EmptyMessage>No result found.</EmptyMessage>
                ) : (
                  boats.map((boat, i) => (
                    <Col xl="6" lg="6" key={i}>
                      <BoatCard
                        id={boat.id}
                        handle={boat.handle}
                        title={`${boat.builder ? boat.builder.name : ""} ${boat.model
                          }`}
                        year={boat.built}
                        lengthM={boat.lengthM}
                        enginePower={boat.enginePower}
                        cabins={boat.cabins}
                        location={boat.location}
                        price={boat.price}
                        currency={boat.currency}
                        listImage={
                          boat.listImage
                            ? `${config.apiURL}${boat.listImage.url}`
                            : undefined
                        }
                      />
                    </Col>
                  ))
                )}
              </Row>
            </Container>
          ) : (

            <Container>
              <Row>
                {boats.length == 0 ? (
                  <EmptyMessage>No result found.</EmptyMessage>
                ) : (
                  boats.map((boat, i) => (
                    <Col xs="12" key={i}>
                      <BoatCard
                        list
                        id={boat.id}
                        handle={boat.handle}
                        title={`${boat.builder ? boat.builder.name : ""} ${boat.model
                          }`}
                        year={boat.built}
                        lengthM={boat.lengthM}
                        cabins={boat.cabins}
                        enginePower={boat.enginePower}
                        location={boat.location}
                        price={boat.price}
                        currency={boat.currency}
                        listImage={
                          boat.listImage
                            ? `${config.apiURL}${boat.listImage.url}`
                            : undefined
                        }
                      />
                    </Col>
                  ))
                )}
              </Row>
            </Container>
          )}
        </ListContent>
      </ListWrapper>

    </React.Fragment>
  );
};
