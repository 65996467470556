import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

export const Pagination = (props) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const newPages = [];
    if (props.data.page > 1) newPages.push('prev');

    if (props.data.page < 5) newPages.push(...[1, 2, 3, 4, 5, '...', props.data.pageCount]);

    if (props.data.page >= 5 && props.data.page <= props.data.pageCount - 4) {
      newPages.push(...[
        1,
        '...',
        props.data.page - 1,
        props.data.page,
        props.data.page + 1,
        '...',
        props.data.pageCount,
      ])
    }

    if (props.data.page > props.data.pageCount - 4) {
      newPages.push(...[
        1,
        '...',
        props.data.pageCount - 4,
        props.data.pageCount - 3,
        props.data.pageCount - 2,
        props.data.pageCount - 1,
        props.data.pageCount,
      ]);
    }

    if (props.data.page < props.data.pageCount) newPages.push('next');

    setPages(newPages);
  }, [props.data.page]);


  // prepare pages

  if (props.data.pageCount < 5) return <></>

  return (
    <PaginationWrapper>
      {pages.map((page, i) => (
        <React.Fragment key={i}>
          {page == 'prev' &&
            <Item key={i} onClick={() => props.onClick(props.data.page - 1)}>
              <svg viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
            </Item>
          }
          {page != 'prev' && page != 'next' && (
            <Item className={`${page == '...' ? 'dots' : ''} ${props.data.page == page ? 'active' : ''}`} onClick={() => page != '...' && props.onClick(page)}>
              {page}
            </Item>
          )}
          {page == 'next' && (
            <Item onClick={() => props.onClick(props.data.page + 1)}>
              <svg viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
            </Item>
          )}
        </React.Fragment>
      ))}
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(232, 232, 232);
  padding-bottom: 24px;
`

const Item = styled.div`
  margin: 0px 4px;
  color: rgb(34, 34, 45);
  border-color: rgba(112, 128, 144, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  outline: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.015em;
  border-radius: 16px;
  text-align: center;
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  padding: 0px 6px;
  margin: 0px 3px;
  color: rgb(34, 34, 45);
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(0, 0, 0, 0.23);

  &:hover { background-color: rgba(0, 0, 0, 0.04);
  }

  &.dots {
    cursor: default;
    border: none;
    background: none;
  }

  &.active {
    background-color: rgb(34, 34, 45);
    color: rgb(255, 255, 255);
  }

  svg {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.25rem;
    margin: 0px -8px;
  }
`
