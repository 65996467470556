import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatCurrency } from "../../services/store.service";

export const getCurrency = (boat) => {
  if (boat.Currency == "Euro") return ["eur", boat.PriceEuro];
  if (boat.Currency == "Usd") return ["usd", boat.PriceUSD];

  return ["usd", boat.PriceUSD];
}

export const BoatCard = (props) => {
  const price = getCurrency(props.data);

  return props.list ? (
    <ListItem dataId={props.id}>
      <Link
        to={`/boat/${props.handle}`}
        style={{ textDecoration: "none", color: "#000", }}
      >
        <CardImage
          loading="lazy"
          src={props.listImage}
          style={{
            height: 260,
            width: 474
          }}
          onError={() => console.log('error')}
        />
        <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Title>
            {props.title} {props.year}
          </Title>
          <Pricing>
            <div>
            </div>
            <Dot />
            <div>
              {props.location}
            </div>
            <Dot />
            <div>
              {props.stockNumber}
            </div>
          </Pricing>
          <Spec>
            <SpecCard>
              <div className="title">Length</div>
              <div className="text">{props.lengthM}</div>
            </SpecCard>
            <SpecCard>
              <div className="title">Price</div>
              <div className="text">
                {formatCurrency(price[1], price[0])}
              </div>
            </SpecCard>
          </Spec>
        </CardContent>
      </Link>
    </ListItem>
  ) : (

    <Card dataId={props.id}>
      <Link
        to={`/boat/${props.handle}`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <CardImage
          loading="lazy"
          src={props.listImage}
          onError={({ currentTarget }) => currentTarget.src = "https://yachtr.com/img/vessel_medium.jpg"}
        />
        <CardContent>
          <Title>
            {props.title} {props.year}
          </Title>
          <Pricing>
            <div>
              {props.location}
            </div>
            <Dot />
            <div>
              {props.stockNumber}
            </div>
          </Pricing>
          <Spec>
            <SpecCard>
              <div className="title">Length</div>
              <div className="text">{props.lengthM}</div>
            </SpecCard>
            <SpecCard>
              <div className="title">Price</div>
              <div className="text">
                {formatCurrency(price[1], price[0])}
              </div>
            </SpecCard>
          </Spec>
        </CardContent>
      </Link>
    </Card>
  );
};

const Card = styled.div`
  box-shadow: 0 0 5px 0 #ccc;
  border-radius: 26px;
  overflow: hidden;
  transition-duration: 0.5s;
  margin-bottom: 25px;
  background: #f5f5f5;

  &:hover {
    box-shadow: 0 3px 10px 0 #21254f55;
  }
`;

const CardImage = styled.img`
  height: 380px;
  width: 100%;
  border-radius: 26px;
  object-fit: cover;

  @media(max-width: 768px) {
    height: 260px;
  }
`;

const CardContent = styled.div`
  padding: 32px;
  padding-top: 20px;
`;

const Title = styled.div`
  color: #020202;
  font-size: 28px;
  font-weight: 600;
  
  @media(max-width: 991px) {
    font-size: 22px;
  }

`;

const Pricing = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #8B8B8B;
  font-size: 18px;
  font-weight: 500;
`;

const ListItem = styled.div`
  box-shadow: 0 0 5px 0 #ccc;
  margin-bottom: 25px;
  border-radius: 12px;
  overflow: hidden;
  border-radius: 26px;
  background: #f5f5f5;

  a {
    display: flex;
  }

  @media(max-width: 991px) {
    a{
      display: block;
    }
  }
`;

const Spec = styled.div`
  margin: 0 -7px;
  margin-top: 32px;
  display: flex;
`;

const SpecCard = styled.div`
  background: #E8E8E8;
  border-radius: 8px;
  flex: 1;
  padding: 8px 8px;
  margin-left: 7px;
  margin-right: 7px;
  min-width: 144px;

  .title {
    color: #8B8B8B;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
  }

  .text {
    line-height: 1.3;
    color: #174FE9;
    font-size: 22px;
    font-weight: 600;
  }

  @media(max-width: 1199px) {
    min-width: auto;
  }
  
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #174FE9;
  margin: 0 16px;
`
