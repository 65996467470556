import styled from "styled-components";
import LoaderSpinner from "react-loader-spinner";

const ButtonWrapper = styled.div`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

const ButtonInput = styled.button`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  outline: none;
  background: ${(props) => (props.dark ? "#174fe9" : "transparent")};
  color: ${(props) => (props.dark ? "#f5f5f5" : "#8b8b8b")};
  border: none;
  border-radius: 28px;
  font-size: 16px;
  padding: 12px 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: ${(props) => (props.dark ? "#0c2d87" : "transparent")};
    color: ${(props) => (props.dark ? "#f5f5f5" : "#174fe9")};
  }

  .icon {
    margin-right: 8px;
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .icon-right {
    margin-left: 8px;
    display: flex; 
    align-items: center;
    justify-content: center;
  }
`;

export const Button = (props) => {
  const onClick = (e) => {
    if (props.onClick) props.onClick(e);
    else console.log("onClick not provided");
  };

  return (
    <ButtonWrapper className="tomb__button" fullWidth={props.fullWidth}>
      <ButtonInput
        dark={props.dark}
        big={props.big}
        fullWidth={props.fullWidth}
        onClick={onClick}
        submit={props.submit}
        disabled={props.disabled}
      >
        {props.icon ? <div className="icon">{props.icon}</div> : undefined}
        {props.text}
        {props.iconRight ? <div className="icon-right">{props.iconRight}</div> : undefined}
        {props.loading ? <LoaderSpinner style={{ marginLeft: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="Oval" color="#FFFFFF" height={15} width={15} /> : undefined}
      </ButtonInput>
    </ButtonWrapper>
  );
};
