import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { TextBox } from "../form/textbox";
import { Dropdown } from "../form/dropdown";
import { Button } from "../form/button";
import BrokerageApi from "../../services/api.service";
import { IconSearch } from "../Icons";

export const Filter = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState(props.initial);
  const [filters, setFilters] = React.useState({
    manufacturers: [],
    countries: []
  });

  const onFilter = (key, value) => {
    const _filter = { ...filter };
    _filter[key] = value;
    setFilter(_filter);
  };

  const onReset = () => {
    window.location = window.location.pathname;
  };

  const onSearch = async () => {
    props.onFilter(filter);
    // window.location = window.location.pathname + query;
  };

  const init = async () => {
    const response = await BrokerageApi.getFilters();
    setFilters(response.data);
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, [window.location]);

  if (loading) return <></>;

  return (
    <FilterWrapper>
      <FormWrapper>
        <Container>
          <Row>
            <Col xl="2" lg="6">
              <Dropdown
                fullWidth
                label="Brands"
                options={filters.manufacturers.map(x => ({ value: x, label: x }))}
                onChange={(v) => onFilter("Manufacturer", v)}
                value={props.initial.Manufacturer}
              />
            </Col>

            <Col xl="2" lg="6">
              <Dropdown
                fullWidth
                label="Location"
                placeholder="USA"
                options={filters.countries.map(x => ({ value: x, label: x }))}
                onChange={(v) => onFilter("Country", v)}
                value={props.initial.Country}
              />
            </Col>

            <Col xl="3" lg="6">
              <Row>
                <Col xs="6">
                  <TextBox
                    label="Price"
                    suffix="$"
                    fullWidth
                    placeholder="From"
                    onChange={(v) => onFilter("price_from", v)}
                    value={props.initial.price_from}
                  />
                </Col>
                <Col xs="6">
                  <TextBox
                    fullWidth
                    suffix="$"
                    placeholder="To"
                    onChange={(v) => onFilter("price_to", v)}
                    value={props.initial.price_to}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="2" lg="6">
              <Row>
                <Col xs="6">
                  <TextBox
                    suffix="ft"
                    label="Length"
                    fullWidth
                    placeholder="from"
                    onChange={(v) => onFilter("length_from", v)}
                    value={props.initial.length_from}
                  />
                </Col>
                <Col xs="6">
                  <TextBox
                    suffix="ft"
                    fullWidth
                    placeholder="to"
                    onChange={(v) => onFilter("length_to", v)}
                    value={props.initial.length_to}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="2" lg="6">
              <Row>
                <Col xs="6">
                  <TextBox
                    label="Year"
                    fullWidth
                    placeholder="From"
                    onChange={(v) => onFilter("year_from", v)}
                    value={props.initial.year_from}
                  />
                </Col>
                <Col xs="6">
                  <TextBox
                    fullWidth
                    placeholder="To"
                    onChange={(v) => onFilter("year_to", v)}
                    value={props.initial.year_to}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="1" lg="12">
              <Right>
                <Button dark text="Search" icon={<IconSearch />} onClick={onSearch} />
                {/*
                <Button text="Reset" icon={<IconArrowCircleRecyle />} onClick={onReset} />
                */}
              </Right>
            </Col>

          </Row>
        </Container>
      </FormWrapper>

    </FilterWrapper >
  );
};

export const Right = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;

  @media(max-width: 768px) {
    justify-content: space-between;
  }
`

const FilterWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 0px;
  position: sticky;
  top: -115px;
  left: 0;
  right: 0;
  background: #F5F5F5;
  z-index: 1000;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;

  .tomb__textbox {
    margin-bottom: 20px;
  }

  &.open {
    top: 0;
  }

  @media (max-width: 992px) {
    top: -400px;
    position: relative;
  }
`;

const FormWrapper = styled.div`
`;


