import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";

import { BoatListPage } from "./pages/boat/list";
import { BoatDetailPage } from "./pages/boat/detail";
import { BuilderPage } from "./pages/builder/detail";
import { Col, Container, Row } from "react-bootstrap";
import { Logo } from "./components/Logo";
import { IconEmail, IconWeb } from "./components/Icons";
import { Center, Left, Right } from "./components/Styled";
import { BoatFeaturedPage } from "./pages/boat/list/featured";

const Header = styled.div`
  padding: 22px 0;
  background: #f5f5f5;
  border-bottom: 1px solid #d2d2d2;
`;

const Footer = styled.div`
  background: #020202;
  padding: 45px 0;
  color: #f5f5f5;
  font-size: 12px;
  text-align: center;
`;

const A = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #020202;
  font-size: 14px;
  font-weight: 500;
  
  span {
    margin: 0 3px
  }
`;

const App = () => {
  const init = async () => { };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className="tomb__app">
        <Header>
          <Container>
            <Row>
              <Col md={3} className="desktop">
                <Left>
                  <A href="https://iconicyachtsgroup.com">
                    <span><IconWeb /></span>
                    <span>iconicyachtgroup.com</span>
                  </A>
                </Left>
              </Col>
              <Col lg={6}>
                <Center>
                  <a href="/">
                    <Logo />
                  </a>
                </Center>
              </Col>
              <Col md={3} className="desktop">
                <Right>
                  <A href="mailto:brokerage@iconicyachtgroup.com">
                    <span><IconEmail /></span>
                    <span>brokerage@iconicyachtgroup.com</span>
                  </A>
                </Right>
              </Col>
            </Row>
          </Container>
        </Header>

        <div
          className="tomb__page"
        >
          <Routes>
            <Route path="/" element={<BoatListPage />} />
            <Route path="/featured" element={<BoatFeaturedPage />} />
            <Route path="/:status" element={<BoatListPage />} />
            <Route path="/boat/:id" element={<BoatDetailPage />} />
            <Route path="/builder/:id" element={<BuilderPage />} />
          </Routes>
        </div>
        <div className="tomb__raider :D"></div>

        <Footer>
          2024 &trade; & &copy; 2024 Iconic Yacht, Legal notice, Privacy Policy
        </Footer>
      </div>
    </BrowserRouter>
  );
};

export default App;
