
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BoatCard } from "../../../components/boatCard";
import { Dropdown } from "../../../components/form/dropdown";
import { Filter } from '../../../components/filter';
import styled from "styled-components";

import { GridButtonIcon } from "../../../components/icon/gridButtonIcon";
import { ListButtonIcon } from "../../../components/icon/listButtonIcon";
import BrokerageApi from "../../../services/api.service";
import { Helmet } from "react-helmet";
import { Loader } from "../../../components/loader";
import { Pagination } from '../../../components/pagination'
import { useSearchParams } from 'react-router-dom';

export const decodeSearchParams = (searchParams) => {
  return [...searchParams.entries()].reduce((acc, [key, val]) => {
    try {
      return {
        ...acc,
        [key]: JSON.parse(val)
      };
    } catch {
      return {
        ...acc,
        [key]: val
      };
    }
  }, {});
};

export const BoatFeaturedPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewMode, setViewMode] = React.useState(0);
  const [data, setData] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [query, setQuery] = React.useState();
  const [params, setParams] = React.useState({});

  React.useEffect(() => {
    const params = decodeSearchParams(searchParams);
    setQuery(params);
    setParams(params);
  }, [])

  React.useEffect(() => {
    const params = decodeSearchParams(searchParams);

    const init = async () => {
      setLoading(true);
      const result = await BrokerageApi.getFeaturedBoats(params);

      if (result.success) {
        setData(result.data);
        setLoading(false);
      } else {
        // an error occured
        setLoading(false)
      }
    };

    init();

  }, [query]);

  const onFilter = (x, type) => {
    let _query = { ...query };

    if (type == 'sort') {
      _query.sort = x;
    }

    if (type == 'filter') {
      _query = { ...query, ...x };
    }

    if (type == 'page') {
      _query.page = x;
    }

    setQuery(_query);
    setParams(_query);
    setSearchParams(_query);
  }

  const onViewSwitch = (i) => {
    setViewMode(i);
  };

  if (loading) return <Loader />;

  const generateHandle = (data) => {
    var items = [data.ExternalID];

    if (data.Manufacturer) items.push(data.Manufacturer.replaceAll(' ', '-'));
    if (data.Model) items.push(data.Model.replaceAll(' ', '-'));
    if (data.Year) items.push(data.Year);
    if (data.City) items.push(data.City.replaceAll(' ', '-'));
    if (data.Country) items.push(data.Country.replaceAll(' ', '-'));

    return items.join("-");
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Iconic Yacht Brokerage</title>
        <meta name="description" content="Our collection of available yachts for sale around the world. Our Yacht Sales Consultants will help ensure it’s the right fit for you and will make sure the purchase process is seamless, transparent and efficient." />
      </Helmet>

      <Filter initial={params} onFilter={x => onFilter(x, 'filter')} />

      <ListWrapper>
        <ListHeader>
          <Container>
            <Row>
              <Col md="12">
                <Title>BEYOND THE HORIZON</Title>
              </Col>

              <Col md={2}></Col>
              <Col md={8}>
                <Description>
                  Our collection of available yachts for sale around the world. Our Yacht Sales Consultants will help ensure it’s the right fit for you and will make sure the purchase process is seamless, transparent and efficient.
                </Description>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Filters>
              <Row>
                <Col md={1} className="desktop">
                  <ViewSwitch>
                    <GridButton
                      selected={viewMode === 0}
                      onClick={() => onViewSwitch(0)}
                    >
                      <GridButtonIcon selected={viewMode === 0} />
                    </GridButton>
                    <ListButton
                      selected={viewMode === 1}
                      onClick={() => onViewSwitch(1)}
                    >
                      <ListButtonIcon selected={viewMode === 1} />
                    </ListButton>
                  </ViewSwitch>
                </Col>

                <Col md={7} className="desktop"></Col>
                <Col md={4} >
                  <DropdownSwitch>
                    <Dropdown
                      placeholder="Sort by"
                      fullWidth
                      dark
                      horizontal
                      options={[
                        {
                          value: "",
                          label: "Sort by: Recommended",
                        },
                        {
                          value: "length_desc",
                          label: "Sort by: Length - Long to Short",
                        },
                        {
                          value: "length_asc",
                          label: "Sort by: Length - Short to long",
                        },
                        {
                          value: "price_desc",
                          label: "Sort by: Price - High to Low",
                        },
                        { value: "price_asc", label: "Sort by: Price - Low to High" },
                      ]}
                      onChange={x => onFilter(x, 'sort')}
                    />

                  </DropdownSwitch>
                </Col>
              </Row>
            </Filters>

          </Container>
        </ListHeader>
        <ListContent>

          <Container>
            <Row>
              {data.results.length == 0 ? (
                <Col>
                  <EmptyMessage>No result found.</EmptyMessage>
                </Col>
              ) : (
                data.results.map((boat, i) => (
                  <Col xl={viewMode == 0 ? 6 : 12} lg={viewMode == 0 ? 6 : 12} key={i}>
                    <BoatCard
                      id={boat.ExternalID}
                      data={boat}
                      list={viewMode == 1}
                      handle={generateHandle(boat)}
                      title={`${boat.Manufacturer} ${boat.Model || ""}`}
                      year={boat.Year}
                      lengthM={`${boat.DisplayLengthFeet} ft`}
                      location={[boat.City, boat.State, boat.Country].filter(x => x).join(", ")}
                      stockNumber={boat.StockNumber || ""}
                      listImage={boat.Gallery[0].Medium}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Container>
        </ListContent>
      </ListWrapper>

      <Pagination data={data.pagination} onClick={e => {
        onFilter(e, 'page');
      }} />
    </React.Fragment>
  );
};


const ListWrapper = styled.div`
  background: #E8E8E8;
  min-height: calc(100vh - 275px);
  padding-bottom: 48px;
`;

const ListHeader = styled.div`
  padding-top: 48px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  line-height: 1.3;
  color: #8B8B8B;
`

const Filters = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ListContent = styled.div`
`;

const ViewSwitch = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 992px) {
    padding-top: 15px;
  }
`;

const DropdownSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
`

const GridButton = styled.div`
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.div`
  text-align: center;
  margin: 32px;
  font-size: 56px;
  font-weight: bold;
  color: #8b8b8b;
`;

