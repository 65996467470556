import Axios from "axios";
import { config } from "./config.service";

const api = Axios.create({
  baseURL: config.apiURL + "/api",
});

const handleApiCall = async (callback) => {
  try {
    return {
      success: true,
      data: await callback(),
    };
  } catch (e) {
    console.log(e);
    return {
      success: false,
      data: e,
    };
  }
};

class BrokerageApi {
  static async getBoats(params) {
    return handleApiCall(async () => {
      const result = await api.get("/vessels", { params });
      return result.data;
    });
  }

  static async getFeaturedBoats(params) {
    return handleApiCall(async () => {
      const result = await api.get("/vessels/featured", { params });
      return result.data;
    });
  }

  static async getFilters() {
    return handleApiCall(async () => {
      const result = await api.get("/vessels/cache");
      return result.data;
    });
  }

  static async getBoat(id) {
    return handleApiCall(async () => {
      const result = await api.get(`/vessels/${id}`);
      return result.data;
    });
  }

  static async getBuilder(id) {
    return handleApiCall(async () => {
      const result = await api.get(`/brands/handle/${id}`);
      return result.data;
    });
  }

  static async getBuilderBoats(id, sorting) {
    return handleApiCall(async () => {
      const params = {};
      if (sorting) params._sort = sorting;

      const result = await api.get(`/brands/handle/${id}/boats`, { params });
      return result.data;
    });
  }

  static async getBuilders() {
    return handleApiCall(async () => {
      const result = await api.get(`/brands/list`);
      return result.data;
    });
  }

  static async getLocations() {
    return handleApiCall(async () => {
      const result = await api.get(`/boats/locations`);
      return result.data;
    });
  }
  static async postContact(id, data) {
    return handleApiCall(async () => {
      const result = await api.post(`/vessels/${id}/contact`, { data });
      return result.data;
    });
  }
}

export default BrokerageApi;
